import React, { FC, useState } from 'react';
import { useUI } from '@components/ui';
import cn from 'clsx';
import useTranslation from 'next-translate/useTranslation';

import type { Product, ProductVariant } from '@vercel/commerce/types/product';
import useCustomer from '@vercel/commerce-shopify/customer/use-customer';
import useAddItem from '@vercel/commerce-shopify/wishlist/use-add-item';
import useRemoveItem from '@vercel/commerce-shopify/wishlist/use-remove-item';
import useWishlist from '@vercel/commerce-shopify/wishlist/use-wishlist';
import { HeartIcon } from '@vibre/icons';
import s from './WishlistButton.module.css';

type Props = {
  productId: Product['id'];
  variant: ProductVariant;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const WishlistButton: FC<Props> = ({
  productId,
  variant,
  className,
  ...props
}) => {
  const { data } = useWishlist();
  const addItem = useAddItem();
  const removeItem = useRemoveItem();
  const { data: customer } = useCustomer();
  const { openModal, setModalView } = useUI();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // @ts-ignore Wishlist is not always enabled
  const itemInWishlist = data?.items?.find(
    // @ts-ignore Wishlist is not always enabled
    (item) => item.product_id === productId && item.variant_id === variant.id
  );

  const handleWishlistChange = async (e: any) => {
    e.preventDefault();

    if (loading) return;

    // A login is required before adding an item to the wishlist
    if (!customer) {
      setModalView('LOGIN_VIEW');
      return openModal();
    }

    setLoading(true);

    try {
      if (itemInWishlist) {
        await removeItem({ id: itemInWishlist.id! });
      } else {
        await addItem({
          productId,
          variantId: variant?.id!,
        });
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <button
      aria-label={itemInWishlist ? t('wishlist.remove') : t('wishlist.add')}
      className={cn(s.root, className)}
      onClick={handleWishlistChange}
      {...props}
    >
      <HeartIcon
        className={cn(s.icon, {
          [s.loading]: loading,
          [s.inWishlist]: itemInWishlist,
        })}
      />
    </button>
  );
};

export default WishlistButton;
