import React from 'react';
import cn from 'clsx';

import s from './ProductTag.module.css';

interface ProductTagProps {
  className?: string;
  name: string;
  vendor: string;
  price: string;
  fontSize?: number;
}

const ProductTag: React.FC<ProductTagProps> = ({
  name,
  vendor = '',
  price,
  className = '',
  fontSize = 32,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <h2 className={s.vendor} style={{ fontSize: fontSize * 0.5 }}>
        <span>{vendor}</span>
      </h2>
      <h3 className={s.name}>
        <span
          className={cn({ [s.fontsizing]: fontSize < 32 })}
          style={{
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize}px`,
          }}
        >
          {vendor ? name.replace(`${vendor} - `, '') : name}
        </span>
      </h3>
      <div className={s.price}>{price}</div>
    </div>
  );
};

export default ProductTag;
