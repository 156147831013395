import { FC, useState } from 'react';
import { Trash } from '@components/icons';
import { Button, Text } from '@components/ui';
import { useUI } from '@components/ui/context';
import cn from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import type { Product } from '@vercel/commerce/types/product';
import type { Wishlist } from '@vercel/commerce/types/wishlist';
import useAddItem from '@vercel/commerce-shopify/cart/use-add-item';
import usePrice from '@vercel/commerce-shopify/product/use-price';
import useRemoveItem from '@vercel/commerce-shopify/wishlist/use-remove-item';
import s from './WishlistCard.module.css';

interface Props {
  item: Wishlist;
}

const placeholderImg = '/product-img-placeholder.svg';

const WishlistCard: FC<Props> = ({ item }) => {
  const product: Product = item.product;
  const { price } = usePrice({
    amount: product.price?.value,
    baseAmount: product.price?.retailPrice,
    currencyCode: product.price?.currencyCode!,
  });
  // @ts-ignore Wishlist is not always enabled
  const removeItem = useRemoveItem({ wishlist: { includeProducts: true } });
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const { t } = useTranslation();

  // TODO: fix this missing argument issue
  /* @ts-ignore */
  const addItem = useAddItem();
  const { openSidebar } = useUI();

  const handleRemove = async () => {
    setRemoving(true);

    try {
      // If this action succeeds then there's no need to do `setRemoving(true)`
      // because the component will be removed from the view
      await removeItem({ id: item.id! });
    } catch (error) {
      setRemoving(false);
    }
  };
  const addToCart = async () => {
    setLoading(true);
    try {
      await addItem({
        productId: String(product.id),
        variantId: String(product.variants[0].id),
      });
      openSidebar();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className={cn(s.root, { 'opacity-75 pointer-events-none': removing })}>
      <div className={`col-span-3 ${s.productBg}`}>
        <div>
          <Image
            src={product.images[0]?.url || placeholderImg}
            width={400}
            height={400}
            alt={product.images[0]?.alt || t('product.image_alt_fallback')}
          />
        </div>
      </div>

      <div className='col-span-7'>
        <h3 className='text-2xl mb-2'>
          <Link href={`/product${product.path}`}>
            <a>{product.name}</a>
          </Link>
        </h3>
        <div className='mb-4'>
          <Text html={product.description} />
        </div>
        <Button
          aria-label={t('cart.add')}
          type='button'
          className={
            'py-1 px-3 border border-secondary rounded-md shadow-sm hover:bg-primary-hover'
          }
          onClick={addToCart}
          loading={loading}
        >
          {/* TODO: adapt from product page */}
          {t('cart.add')}
        </Button>
      </div>
      <div className='col-span-2 flex flex-col justify-between'>
        <div className='flex justify-end font-bold'>{price}</div>
        <div className='flex justify-end'>
          <button onClick={handleRemove}>
            <Trash />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WishlistCard;
