import { FC, memo, MouseEventHandler } from 'react';
import { ArrowLeft, ArrowRight } from '@components/icons';
import cn from 'clsx';

import s from './ProductSliderControl.module.css';

interface ProductSliderControlProps {
  onPrev: MouseEventHandler<HTMLButtonElement>;
  onNext: MouseEventHandler<HTMLButtonElement>;
}

const ProductSliderControl: FC<ProductSliderControlProps> = ({
  onPrev,
  onNext,
}) => (
  <div className={s.control}>
    <button
      className={cn(s.leftControl)}
      onClick={onPrev}
      aria-label='Previous Product Image'
    >
      <ArrowLeft />
    </button>
    <button
      className={cn(s.rightControl)}
      onClick={onNext}
      aria-label='Next Product Image'
    >
      <ArrowRight />
    </button>
  </div>
);

export default memo(ProductSliderControl);
