import { FC, useEffect, useState } from 'react';
import { ProductOptions } from '@components/product';
import { Button, Text, useUI } from '@components/ui';
import useTranslation from 'next-translate/useTranslation';

import type { Product } from '@vercel/commerce/types/product';
import { useAddItem } from '@vercel/commerce-shopify/cart';
import {
  getProductVariant,
  selectDefaultOptionFromProduct,
  SelectedOptions,
} from '../helpers';
import s from './ProductSidebar.module.css';

interface ProductSidebarProps {
  product: Product;
  className?: string;
}

const ProductSidebar = ({
  product,
  className,
}: ProductSidebarProps): JSX.Element => {
  // @ts-ignore
  const addItem = useAddItem();
  const { t } = useTranslation();
  const { openSidebar } = useUI();
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});

  useEffect(() => {
    selectDefaultOptionFromProduct(product, setSelectedOptions);
  }, [product]);

  const variant = getProductVariant(product, selectedOptions);
  const addToCart = async () => {
    setLoading(true);
    try {
      await addItem({
        productId: String(product.id),
        variantId: String(variant ? variant.id : product.variants[0]?.id),
      });
      openSidebar();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className={className}>
      <ProductOptions
        options={product.options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <Text
        className='pb-4 break-words w-full max-w-xl'
        html={product.descriptionHtml || product.description}
      />
      {/*<div className='flex flex-row justify-between items-center'>*/}
      {/*  <Rating value={4} />*/}
      {/*  <div className='text-accent-6 pr-1 font-medium text-sm'>36 reviews</div>*/}
      {/*</div>*/}
      <div>
        <Button
          aria-label={t('cart.add')}
          type='button'
          className={s.button}
          onClick={addToCart}
          loading={loading}
          disabled={variant?.availableForSale === false}
        >
          {variant?.availableForSale === false
            ? t('global.not_available')
            : t('cart.add')}
        </Button>
      </div>
      {/*<div className='mt-6'>*/}
      {/*  <Collapse title='Care'>*/}
      {/*    This is a limited edition production run. Printing starts when the*/}
      {/*    drop ends.*/}
      {/*  </Collapse>*/}
      {/*  <Collapse title='Details'>*/}
      {/*    This is a limited edition production run. Printing starts when the*/}
      {/*    drop ends. Reminder: Bad Boys For Life. Shipping may take 10+ days due*/}
      {/*    to COVID-19.*/}
      {/*  </Collapse>*/}
      {/*</div>*/}
    </div>
  );
};

export default ProductSidebar;
